@import '@mercadona/styles/sass/mixins';

html,
body {
  height: 100%;
}

.heading05Semibold {
  @include font-heading05-semibold;
}

.body01Regular {
  @include font-body01-regular;
}

.body03Regular {
  @include font-body01-regular;
}

.body02Semibold {
  @include font-body02-semibold;
}

.text03 {
  color: var(--text03);
}

.interactive01 {
  color: var(--interactive01);
}

.pointer {
  cursor: pointer;
}
